import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import toast from "react-hot-toast";
import { BiLogoGmail } from "react-icons/bi";

type Props = {};

const Composio = (props: Props) => {
  const create = api.agent.composio.connect.useMutation();
  const fetch = api.agent.composio.fetch.useMutation();
  const { organizationId } = useOrganization<true>();

  const { data = [], isLoading } =
    api.agent.composio.list.useQuery(organizationId);

  const handleCreate = async () => {
    const res = await create.mutateAsync(organizationId);

    window.open(res, "_blank");
  };

  const handleFetch = (id: string) => async () => {
    const data = await fetch.mutateAsync(id);
    toast.success("Fetched emails");
  };

  return (
    <div className="gap-4 grid">
      <button
        onClick={handleCreate}
        className="btn w-fit ml-auto btn-sm btn-primary
    "
      >
        <BiLogoGmail /> Connect your email{" "}
        <LoadingSpin loading={create.isLoading} />
      </button>

      <div className="grid gap-2">
        {data.map((d) => (
          <div className="alert">
            <p className="label">Gmail Integration</p>

            <button
              className="btn btn-sm btn-primary"
              onClick={handleFetch(d.id)}
            >
              {fetch.variables === d.id && (
                <LoadingSpin loading={fetch.isLoading} />
              )}
              Fetch
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Composio;
